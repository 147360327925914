<template>
    <div class="site-footer">
        <div class="copyright-bar">
          <div class="container">
            <div class="pull-left flip copyright">&copy; <a :href="siteInfo.site_domain">{{siteInfo.site_name}}</a> - All Rights Reserved</div>
            <div class="pull-right flip payment">
              <div class="footer-payment-logo">
                <ul class="cash-card card-inline">
<!--                  <li class="card-item"><img src="../../assets/images/footer/payment-icon/1.png" alt="" width="52"></li>-->
<!--                  <li class="card-item"><img src="../../assets/images/footer/payment-icon/2.png" alt="" width="52"></li>-->
<!--                  <li class="card-item"><img src="../../assets/images/footer/payment-icon/3.png" alt="" width="52"></li>-->
<!--                  <li class="card-item"><img src="../../assets/images/footer/payment-icon/4.png" alt="" width="52"></li>-->
<!--                  <li class="card-item"><img src="../../assets/images/footer/payment-icon/5.png" alt="" width="52"></li>-->
                </ul>
              </div><!-- /.payment-methods -->
            </div>
          </div><!-- /.container -->
        </div><!-- /.copyright-bar -->
    </div>
</template>

<script>
    import { copyRight } from "@/api/website"
    import { mapGetters } from "vuex"
    export default {
        props: {},
        data() {
            return {}
        },
        computed: {},
        created() {
          this.$store.dispatch("site/copyRight")
          this.$store.dispatch("site/siteInfo")
        },
        mounted() {},
        watch: {},
        methods: {},
        computed: {
            ...mapGetters(["copyRight", "siteInfo"])
        }
    }
</script>

<style scoped lang="scss">
    .site-footer{
      margin-bottom: -50px;
    }
    .footer-bottom {
        width: 100%;
        height: 85px;
        margin: 0 auto;
        padding: 20px 0;
        p {
            margin: 0;
            width: 100%;
            box-sizing: border-box;
            text-align: center;
            img {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
            .footer-link, .el-link.el-link--default {
                color: #9d9d9d;
            }
			.footer-link:hover, .el-link.el-link--default:hover {
				color: $base-color;
			}
        }
        .site-info {
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                width: auto;
                margin: 0 10px;

                i {
                    vertical-align: bottom;
                    margin-right: 5px;
                }
            }
        }
    }
</style>
