import { userAgent } from 'ua_parser'

var config = {
	// api请求地址
	baseUrl: 'https://www.maxbuy.com.gh',
	// 图片域名
	imgDomain: "https://www.maxbuy.com.gh",
	// H5端域名
	h5Domain: "https://www.maxbuy.com.gh/h5",
	// 腾讯地图key
	mpKey: "AIzaSyDZg2xMFSvkVVNzxhwCsX1yc3J2A_Kro3k",
	//客服地址
	webSocket:"wss://www.maxbuy.com.gh/wss",
	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500
};

if (process.env.NODE_ENV !== 'production') {
	// config.baseUrl = 'https://www.maxbuy.com.gh/';
	config.baseUrl = 'http://mm.kiduc.test/';
	// config.imgDomain = 'https://www.maxbuy.com.gh/';
	config.imgDomain = 'http://mm.kiduc.test/';
	config.h5Domain = 'http://mm.kiduc.test/h5';
	config.mpKey = '';
	config.webSocket = 'wss://www.maxbuy.com.gh/wss';
}

window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;

const step = () => {
	const ua_result = userAgent();

	if (ua_result.platform != 'pc') {
		window.location.href = config.h5Domain
	}
	requestAnimationFrame(step)
};

step();

export default config;
