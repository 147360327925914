<template>
  <div class="footer">
    <el-tabs v-model="activeName" class="friendly-link" v-if="linkList.length > 0">
      <el-tab-pane label="Friend links" name="first">
        <div>
          <div class="link-item" v-for="(link_item, link_index) in linkList" :key="link_index"
               :title="link_item.link_title">
            <span @click="linkUrl(link_item.link_url, link_item.is_blank)"><img :src="$img(link_item.link_pic)"/></span>
          </div>

          <div class="clear"></div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="footer-top" v-if="shopServiceList.length > 0">
      <ul class="service">
        <li v-for="(item, index) in shopServiceList" :key="index">
          <div>
            <img :src="$img(item.icon)" alt="" v-if="item.icon">
          </div>
          <p>{{ item.service_name }}</p>
        </li>
      </ul>
    </div>


    <div class="footer-bottom-widgets" style="margin-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-7 col-md-push-5" >
            <div class="columns" v-for="(item, index) in helpList" :key="index" v-if="index<3">
              <aside class="widget clearfix widget_nav_menu">
                <div class="body">
                  <h4 class="widget-title" @click="clickToHelp(item.class_id)"><a>{{ item.class_name }}</a></h4>
                  <div class="menu-footer-menu-1-container">
                    <ul id="menu-footer-menu-1" class="menu">
                      <template v-for="(helpItem, helpIndex) in item.child_list">
                        <li class="help-li" v-if="!helpItem.link_address" @click="clickToHelpDetail(helpItem.id)">
                          <a>{{ helpItem.title }}</a>
                        </li>
                        <li class="help-li" v-else @click="clickJump(helpItem.link_address)">{{ helpItem.title }}</li>
                      </template>
                    </ul>
                  </div>
                </div>
              </aside>
            </div><!-- /.columns -->

          </div><!-- /.col -->

          <div class="footer-contact col-xs-12 col-sm-12 col-md-5 col-md-pull-7">
            <div class="footer-logo">
              <img v-if="siteInfo.logo" :src="$img(siteInfo.logo)"  width="176px" height="43px" />
              <img v-else src="@/assets/images/logo.png" width="176px" height="43px" />
            </div><!-- /.footer-contact -->

            <div class="footer-call-us">
              <div class="media">
                <span class="media-left call-us-icon media-middle"><i class="ec ec-support"></i></span>
                <div class="media-body">
                  <span class="call-us-text">Got Questions? Call us 24/7!</span>
                  <span class="call-us-number">{{siteInfo.telephone}} {{siteInfo.mobile}}</span>
                </div>
              </div>
            </div><!-- /.footer-call-us -->


            <div class="footer-address" style="display: none">
              <strong class="footer-address-title">Contact Info</strong>
              <address>{{siteInfo}}</address>
            </div><!-- /.footer-address -->

            <div class="footer-social-icons">
              <ul class="social-icons list-unstyled">
                <li v-for="(icon, i) in iconList" :key="i" v-if="icon.href"><a class="fa" :class="icon.icon" :href="icon.href" target="_blank"></a></li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="footer-bot">
      <copy-right/>
    </div>
  </div>
</template>

<script>
import {
  copyRight,
  shopServiceLists,
  friendlyLink,
  weQrcode, adList
} from "@/api/website"
import CopyRight from "./CopyRight"
import {
  mapGetters
} from "vuex"
import {
  helpList
} from "@/api/cms/help"

export default {
  props: {},
  data() {
    return {
      shopServiceList: [],
      linkList: [],
      helpList: [],
      ishide: false,
      activeName: "first",
      qrcode: "",
      iconList: [
        {icon: 'fa-facebook', href: ''},
        {icon: 'fa-twitter', href: ''},
        {icon: 'fa-instagram', href: ''},
        {icon: 'fa-youtube', href: ''},
        {icon: 'fa-android', href: ''},
        {icon: 'fa-apple', href: ''},
      ]
    }
  },
  computed: {
    ...mapGetters(["siteInfo"])
  },
  created() {
    this.getShopServiceLists()
    this.link()
    this.getHelpList()
    this.getqrcodeimg()
    this.getAdvs()
  },
  mounted() {
  },
  watch: {},
  methods: {
    getAdvs() {
      [
          ['LINK_FACEBOOK', 0],
          ['LINK_TWITTER', 1],
          ['LINK_INSTAGRAM', 2],
          ['LINK_YOUTUBE', 3],
          ['LINK_ANDROID', 4],
          ['LINK_IOS', 5]
      ].forEach(([keyword, key]) => {
        adList({ keyword })
          .then((res) => {
            if (res &&  res.data.adv_list.length) {
              this.iconList[key].href =  res.data.adv_list[ res.data.adv_list.length - 1].adv_url ? JSON.parse(res.data.adv_list[ res.data.adv_list.length - 1].adv_url).url : '';
            }
          })
          .catch(() => {
            this.iconList[key].href = '';
          })
      })
    },
    getqrcodeimg() {
      weQrcode({}).then(res => {
        if (res.code == 0 && res.data) {
          this.qrcode = res.data
        }
      })
          .catch(err => {
            this.$message.error(err.message)
          })
    },
    getShopServiceLists() {
      shopServiceLists({}).then(res => {
        if (res.code == 0 && res.data) {
          this.shopServiceList = res.data
        }
      })
          .catch(err => {
            this.shopServiceList = []
          })
    },
    link() {
      friendlyLink({})
          .then(res => {
            if (res.code == 0 && res.data) {
              this.linkList = res.data
            }
          })
          .catch(err => {
            this.$message.error(err.message)
          })
    },
    linkUrl(url, target) {
      if (!url) return
      if (url.indexOf("http") == -1) {
        if (target) this.$router.pushToTab({
          path: url
        })
        else this.$router.push({
          path: url
        })
      } else {
        if (target) window.open(url)
        else window.location.href = url
      }
    },
    /**
     * 获取帮助列表
     */
    getHelpList() {
      helpList()
          .then(res => {
            if (res.code == 0 && res.data) {
              var arr = [];
              arr = res.data.slice(0, 4)
              console.log(arr)
              for (let i = 0; i < arr.length; i++) {
                arr[i].child_list = arr[i].child_list.slice(0, 4);
              }

              this.helpList = arr
              console.log(helpList)
            }
          })
          .catch(err => {
          })
    },
    /**
     * 图片加载失败
     */
    imageError() {
      this.siteInfo.web_qrcode = "../../assets/images/wxewm.png"
    },
    /**
     * 跳转到帮助列表
     */
    clickToHelp(id) {
      this.$router.push("/cms/help/listother-" + id)
    },
    /**
     * 跳转到帮助详情
     */
    clickToHelpDetail(id) {
      this.$router.push("/cms/help-" + id)
    },
    /**
     * 跳转到帮助详情
     */
    clickJump(address) {
      location.href = address;
    }
  },
  components: {
    CopyRight
  }
}
</script>

<style scoped lang="scss">
.footer {
  .footer-top {
    background-color: #fff;

    .service {
      margin: 0;
      padding: 0;
      width: $width;
      margin: 0 auto;
      padding: 50px 0;
      box-sizing: border-box;
      border-bottom: 1px solid #e9e9e9;
      display: flex;
      justify-content: space-around;
      align-items: center;

      li {
        list-style: none;
        line-height: 50px;
        text-align: center;
        flex: 1;

        div {
          width: 48px;
          height: 48px;
          line-height: 48px;
          display: flex;
          overflow: hidden;
          align-items: center;
          justify-content: center;
          margin: 0 auto;

          img {
            height: 100%;
          }
        }

        p {
          font-size: 16px;
          line-height: 20px;
          margin-top: 10px;
        }
      }
    }
  }

  .footer-center {
    width: $width;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 50px 0;

    .left {
      width: 300px;

      .left-phone {
        font-size: 26px;
        font-weight: 600;
        color: $base-color;
        padding-bottom: 10px;
        border-bottom: 1px solid $base-color;
        margin-bottom: 12px;
      }

      .left-email {
        font-size: 18px;
        color: #838383;
      }
    }

    .center {
      width: calc(100% - 550px);
      padding: 0 68px;
      display: flex;
      justify-content: space-between;

      p {
        font-size: 18px;
        font-weight: 600;
        color: #838383;
        margin-bottom: 10px;
        cursor: pointer;
      }

      .help-li {
        font-size: 14px;
        color: #838383;
        line-height: 30px;
        cursor: pointer;
      }

      p:hover, .help-li:hover {
        color: $base-color;
      }
    }

    .right {
      width: 250px;
      text-align: center;

      .el-image {
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      p {
        font-size: 18px;
        color: #838383;
      }
    }

    .qrcode-hide {
      display: none;
    }
  }

  .footer-bot {
    background: #242424;
    color: #9D9D9D;
  }

  .friendly-link {
    width: $width;
    margin: 0 auto;
    border: 1px solid #e9e9e9;

    .link-title {
      line-height: 30px;
      padding: 10px 0 5px;
      margin: 0px 0 15px;
      border-bottom: 1px solid #e8e8e8;
    }

    .link-item {
      width: 10%;
      height: 50px;
      line-height: 47px;
      float: left;
      text-align: center;
      overflow: hidden;
      margin: 0 6px 10px 6px;
      -webkit-transition: opacity 0.3s, box-shadow 0.3s;
      transition: opacity 0.3s, box-shadow 0.3s;
      border: 1px solid #fff;
      border-left: none;
      cursor: pointer;
    }

    .link-item:hover {
      width: -webkit-calc(10% + 1px);
      width: calc(10% + 1px);
      margin-left: 4px;
      position: relative;
      opacity: 1;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border: 1px solid #dddddd;
    }

    .clear {
      clear: both;
    }
  }
}
</style>
<style lang="scss">
.friendly-link {
  .el-tabs__nav-scroll {
    padding-left: 30px;
    height: 50px;
    line-height: 50px;
  }

  .el-tabs__content {
    padding: 0 20px;
  }

  .el-tabs__nav-wrap::after {
    height: 1px;
  }

  .el-tabs__nav {
    padding: 0 20px;
  }

  .el-tabs__active-bar {
    padding: 0 20px;
  }
}
</style>
