export const lang = {
	//公共
	common: {
		//语言包名称
		name: 'Chinese',
		hello_login: 'Login to MAXBUY',
		hello_logout: 'Logout',
		hello_register: 'Registration',
		my_orders: 'My order',
		my_footprints: 'My footprints',
		my_collections: 'My colleoction',
		my_cart: 'My Shopping',
		help_center: 'Help',
		all_categories: 'All Category',
		search: 'Search'
	}
}
