<template>
<div class="row">

  <!-- ============================================================= Header Logo ============================================================= -->
  <div class="header-logo">
    <router-link to="/" class="header-logo-link">
      <img v-if="siteInfo.logo" :src="$img(siteInfo.logo)"  width="176px" height="43px" />
      <img v-else src="@/assets/images/logo.png" width="176px" height="43px" />
    </router-link>
  </div>
  <!-- ============================================================= Header Logo : End============================================================= -->

  <form class="navbar-search" @submit.stop.prevent="search()">
    <label class="sr-only screen-reader-text" for="search">Search for:</label>
    <div class="input-group">
      <input type="text" id="search" class="form-control search-field" dir="ltr" :placeholder="defaultSearchWords" v-model="keyword" @keyup.enter="search" placeholder="Search for products" />
<!--      <div class="input-group-addon search-categories" v-if="searchType == 'goods'">-->
<!--        <select name='goods_category' v-model="other_query" class='postform resizeselect'  v-if="goodsCategoryTree.length">-->
<!--          <option value='0' selected='selected'>All Categories</option>-->
<!--          <option class="level-0" :value="JSON.stringify({ category_id: item.category_id, level: item.level })" v-for="(item, index) in goodsCategoryTree" :key="index" >{{ item.category_name }}</option>-->
<!--        </select>-->
<!--      </div>-->
      <div class="input-group-btn">
        <button class="btn btn-secondary"><i class="ec ec-search"></i></button>
      </div>
    </div>
  </form>
  <ul class="navbar-mini-cart navbar-nav animate-dropdown nav pull-right flip">
    <li class="nav-item dropdown">

      <router-link  class="nav-link" data-toggle="dropdown" to="/cart">
        <i class="ec ec-shopping-bag"></i>
        <span class="cart-items-count count" v-if="cartTotal">{{cartTotal}}</span>
        <span class="cart-items-total-price total-price"><span class="amount">₵{{cartTotalPrice}}</span></span>
      </router-link>
      <ul class="dropdown-menu dropdown-menu-mini-cart" :class="{ overflow: cartList.length > 5 }">
        <li>
          <div class="widget_shopping_cart_content" v-if="cartList.length">

            <ul class="cart_list product_list_widget " :class="{ overflow: cartList.length > 5 }">


              <li class="mini_cart_item" v-for="(item, index) in cartList" :key="index">
                <a title="Remove this item" class="remove" @click="deleteCart(index)">×</a>
                <a href="single-product.html">
                  <img class="attachment-shop_thumbnail size-shop_thumbnail wp-post-image" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" :alt="item.sku_name">
                  {{ item.sku_name }}&nbsp;
                </a>

                <span class="quantity">{{ item.num }} × <span class="amount">₵ {{ item.discount_price }}</span></span>
              </li>


            </ul><!-- end product list -->


            <p class="total"><strong>Subtotal:</strong> <span class="amount">₵{{cartTotalPrice}}</span></p>


            <p class="buttons">
              <router-link  class="button wc-forward" to="/cart">View Cart</router-link >
              <a class="button checkout wc-forward" href="checkout.html">Checkout</a>
            </p>


          </div>
          <div class="empty" v-else>
            <i class="iconfont icongouwuche"></i>
            <span>Your shopping cart is empty. Go shopping and choose goods quickly!</span>
          </div>
        </li>
      </ul>
    </li>
  </ul>

  <ul class="navbar-wishlist nav navbar-nav pull-right flip">
    <li class="nav-item">
      <router-link to="/member/collection" class="nav-link"><i class="ec ec-favorites"></i></router-link>
    </li>
  </ul>
</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import {
		apiHotSearchWords,
		apiDefaultSearchWords
	} from '@/api/pc';
	import {
		cartList as apiCartList
	} from '@/api/goods/cart';
  import {tree} from "@/api/goods/goodscategory";
	export default {
		props: {},
		data() {
			return {
				searchType: 'goods',
				searchTypeText: 'Goods',
				keyword: '',
				hotSearchWords: [],
        goodsCategoryTree: [],
				defaultSearchWords: '',
				cartList: [],
        cartTotal: 0,
				cartTotalPrice: 0,
        other_query: '0'
			};
		},
		components: {},
		computed: {
			...mapGetters(['cartCount', 'siteInfo', 'defaultGoodsImage', 'member'])
		},
		created() {
			this.keyword = this.$route.query.keyword || '';
			if (this.$route.name == 'street') this.searchType = 'shop';
			else this.searchType = 'goods';
			this.$store.dispatch('site/siteInfo');
			this.getHotSearchWords();
			this.getDefaultSearchWords();
			this.getCartList();
      this.getTree();
		},
		watch: {
			searchType() {
				this.searchTypeText = this.searchType == 'goods' ? 'Goods' : 'Shop';
			},
			$route(curr) {
				if (this.keyword !== curr.query.keyword) {
					this.keyword = curr.query.keyword;
				}
				if (this.$route.name == 'street') this.searchType = 'shop';
				else this.searchType = 'goods';
			},
			cartCount() {
				if (this.member) this.getCartList();
			},
			member() {
				if (!this.member) {
					this.$store.commit('cart/SET_CART_COUNT', 0);
					this.cartList = [];
          this.cartTotalPrice = 0;
          this.cartTotal = 0;
				}
			}
		},
		methods: {
      getTree() {
        tree({
          level: 3,
          template: 2
        })
            .then(res => {
              if (res.code == 0 && res.data) {
                console.log(res, '分类菜单')
                if(res.data.length >= 11) this.goodsCategoryTree = res.data
                this.goodsCategoryTree = res.data.slice(0,12)
              }
            })
            .catch(err => {
              this.$message.error(err.message);
            });
      },
			handleCommand(command) {
				this.searchType = command;
			},
			search() {
				if (this.searchType == 'goods') {
          this.$router.push({
            path: '/list',
            query: this.other_query == '0' ? {
              keyword: this.keyword ? this.keyword : this.defaultSearchWords
            } : Object.assign({
              keyword: this.keyword ? this.keyword : this.defaultSearchWords
            }, JSON.parse(this.other_query))
          });
        } else this.$router.push({
					path: '/street',
					query: {
						keyword: this.keyword
					}
				});
				return false;
			},
			getHotSearchWords() {
				apiHotSearchWords({}).then(res => {
					if (res.code == 0 && res.data.words) {
						this.hotSearchWords = res.data.words.split(',');
					}
				});
			},
			getDefaultSearchWords() {
				apiDefaultSearchWords({}).then(res => {
					if (res.code == 0 && res.data.words) {
						this.defaultSearchWords = res.data.words;
					}
				});
			},
			// 获取购物车数据
			getCartList() {
				apiCartList({})
					.then(res => {
						if (res.code >= 0 && res.data.length) {
							this.cartList = res.data;
							this.cartTotalPrice = 0
              this.cartTotal = 0;
							this.cartList.forEach(item => {
								console.log(item.discount_price, 'Price')
                let _cartTotal = parseInt(item.num);
								let _cartTotalPrice = parseFloat(item.discount_price) * parseInt(item.num);
                this.cartTotal += _cartTotal;
								this.cartTotalPrice += parseFloat(_cartTotalPrice)
                console.log(this.cartTotal)
								console.log(this.cartTotalPrice)
							});
							this.cartTotalPrice = this.cartTotalPrice.toFixed(2);
						}
					})
					.catch(res => {});
			},
			imageError(index) {
				this.cartList[index].sku_image = this.defaultGoodsImage;
			},
			// 删除单个购物车商品
			deleteCart(index) {
				this.$store
					.dispatch('cart/delete_cart', {
						cart_id: this.cartList[index].cart_id.toString()
					})
					.then(res => {
						if (res.code >= 0) {
							this.cartList.splice(index, 1);
							this.cartList.forEach(item => {
								let _cartTotalPrice = parseFloat(item.discount_price) * parseFloat(item.num);
								this.cartTotalPrice += parseFloat(_cartTotalPrice)
							});
							console.log(this.cartTotalPrice)
							this.cartTotalPrice = this.cartTotalPrice.toFixed(2);
							this.$forceUpdate();
						}
					})
					.catch(err => {});
			}
		}
	};
</script>

<style scoped lang="scss">
  #search.form-control{
    height: calc(1.5em + 2.1rem + 4px);
  }
  .header-v1 .navbar-search .input-group .btn{
    border-width: 2px;
    border-top-width: 3px;
    border-right-width: 3px;
  }
	.header-in {
		width: $width;
		height: 89px;
		margin: 20px auto 0;

		.logo-wrap {
			width: 240px;
			height: 68px;
			line-height: 68px;
			display: block;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}

		.in-sousuo {
			width: 550px;
			margin-top: 10px;

			.sousuo-box {
				width: 100%;
				height: 36px;
				border: 2px solid $base-color;
				box-sizing: border-box;

				.el-dropdown {
					padding: 0 10px;
					cursor: pointer;

					&::after {
						content: '';
						border-left: 1px solid #cfcfcf;
						margin-left: 5px;
					}
				}

				input {
					width: 380px;
					height: 22px;
					background: none;
					outline: none;
					border: none;
					margin: 4px;
				}

				button {
					border-radius: 0;
					float: right;
				}
			}

			.hot-search-words {
				width: 100%;
				height: 20px;
				margin-top: 5px;
				font-size: 12px;

				span {
					float: left;
				}

				ul {
					overflow: hidden;
					margin: 0;
					height: 21px;
					padding: 0;
					color: $ns-text-color-black;

					li {
						cursor: pointer;
						list-style: none;
						float: left;
						margin-right: 10px;

						&:hover {
							color: $base-color;
						}
					}
				}
			}
		}

		.cart-wrap {
			position: relative;

			.cart {
				margin-top: 10px;
				width: 125px;
				height: 36px;
				//padding: 0 28px 0 19px;
				//border: 1px solid #dfdfdf;
				//color: $base-color;
				font-size: $ns-font-size-sm;
				display: block;
				position: absolute;
				left: 10px;
				z-index: 101;
        .cart-box{
          width: 36px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          background: #000;
          display: inline-block;
        }
				span {
					cursor: pointer;
					line-height: 38px;
					margin-left: 10px;
				}
			}

			&:hover {
				.cart {
					border-bottom: 1px solid #ffffff;
				}

				.list {
					display: block;
				}
			}

			.list {
				position: absolute;
				top: 47px;
				right: 0;
				width: 340px;
				background: #fff;
				border: 1px solid #dfdfdf;
				display: none;
				z-index: 100;

				h4 {
					height: 25px;
					padding: 6px 8px;
					line-height: 25px;
				}

				.overflow-wrap {
					width: 340px;
					overflow: hidden;

					ul {
						max-height: 335px;

						&.overflow {
							overflow: auto;
							width: 354px;
						}

						li {
							background-color: #fff;
							display: block;
							font-size: 12px;
							padding: 8px 10px;
							position: relative;
							border-bottom: 1px solid #dfdfdf;
							overflow: hidden;

							.img-wrap {
								width: 50px;
								height: 50px;
								margin-right: 5px;
								overflow: hidden;
								float: left;
								text-align: center;
								line-height: 50px;
							}

							.goods-name {
								float: left;
								width: 140px;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								overflow: hidden;
								margin-right: 10px;
							}

							.operation {
								float: right;
								text-align: right;
								width: 90px;

								p {
									color: $base-color;
									overflow: hidden;
									text-overflow: ellipsis;
								}

								span {
									cursor: pointer;

									&:hover {
										color: $base-color;
									}
								}
							}

							&:last-child {
								border-bottom: 0;
							}
						}
					}
				}

				.total {
					background-color: #fff;
					display: block;
					font-size: 12px;
					padding: 8px 10px;
					position: relative;
					border-bottom: 1px solid #dfdfdf;
					overflow: hidden;
					background-color: #f0f0f0;
					border-bottom: 0;
					left: 0;

					span {
						margin-top: 5px;
						display: inline-block;
						width: 70%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						strong {
							margin: 0 2px;
							color: $base-color;
							font-size: $ns-font-size-base;
						}
					}

					button {
						float: right;
					}
				}

				.empty {
					width: auto;
					height: 70px;
					line-height: 70px;
					text-align: center;
					color: #999;

					i {
						font-size: 28px;
					}

					span {
						display: inline-block;
						font-size: 12px;
						padding-right: 20px;
						margin-left: 10px;
					}
				}
			}
		}
	}
</style>
