<template>
  <div class="hfeed site">
    <div class="top-bar hidden-md-down">
      <div class="container">
        <nav>
          <ul id="menu-top-bar-left" class="nav nav-inline pull-left animate-dropdown flip">
            <li class="menu-item animate-dropdown"  v-if="logined">
              <div class="member-info">
                <router-link to="/member/index">{{ member.nickname || member.username }}</router-link>
                <span @click="logout">{{$lang('common.hello_logout', $route)}}</span>
              </div>
            </li>
            <li class="menu-item animate-dropdown" v-else>
              <router-link to="/login" >{{$lang('common.hello_login', $route)}}</router-link>
            </li>
          </ul>
        </nav>
        <nav>
          <ul id="menu-top-bar-right" class="nav nav-inline pull-right animate-dropdown flip">
            <li class="menu-item animate-dropdown" v-if="!logined">
              <router-link to="/register">{{ $lang('common.hello_register', $route) }}</router-link>
            </li>
            <li class="menu-item animate-dropdown">
              <router-link to="/member/order_list">{{ $lang('common.my_orders', $route) }}</router-link>
            </li>
            <li class="menu-item animate-dropdown">
              <router-link to="/member/footprint">{{ $lang('common.my_footprints', $route) }}</router-link>
            </li>
            <li class="menu-item animate-dropdown">
              <router-link to="/member/collection">{{ $lang('common.my_collections', $route) }}</router-link>
            </li>
            <li class="menu-item animate-dropdown">
              <router-link to="/cms/help">{{ $lang('common.help_center', $route) }}</router-link>
            </li>
            <li class="menu-item animate-dropdown" v-if="wapQrcode">
              <el-popover
                  placement="bottom-start"
                  trigger="hover">
                <a href="javascript:void(0)" slot="reference">Mobile Shop</a>
                <img :src="$util.img(qrcode) + '?t=' + Date.now() " alt="" >
              </el-popover>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import {getToken} from "@/utils/auth"
import {tree} from "@/api/goods/goodscategory";
import util from '@/utils/util'

export default {
  props: {},
  data() {
    return {
    }
  },
  created() {
    this.$store.dispatch("site/qrCodes")
    this.$store.dispatch("member/member_detail")
    this.$store.dispatch("site/defaultFiles")
    this.$store.dispatch("site/addons")
  },
  mounted() {
  },
  watch: {},
  methods: {
    logout() {
      this.$store.dispatch("member/logout")
    }
  },
  components: {},
  computed: {
    ...mapGetters(["wapQrcode", "member", "addonIsExit"]),
    qrcode: function () {
      return this.wapQrcode === "" ? "" : this.wapQrcode.path.h5.img
    },
    logined: function () {
      return this.member !== undefined && this.member !== "" && this.member !== {}
    }
  }
}
</script>

<style scoped lang="scss">
.container{
  width: $width;
  max-width: $width !important;
}
.header-top {
  width: 100%;
  height: 31px;
  font-size: 12px;
  background-color: #f5f5f5;

  .el-dropdown {
    font-size: $ns-font-size-sm;
  }

  .top-content {
    width: $width;
    height: 100%;
    margin: 0 auto;

    .top-right, .top-left {
      height: 100%;
      float: right;
      font-size: $ns-font-size-sm;
      color: $ns-text-color-black;

      a {
        float: left;
        line-height: 31px;
        padding: 0 10px;

        &:hover {
          color: $base-color;
        }
      }

      div {
        float: left;
        height: 100%;
        margin-left: 10px;
        cursor: pointer;
        line-height: 31px;
        padding: 0 5px;

        &.member-info {
          margin-left: 0;

          span:first-child {
            margin-right: 10px;
          }
        }

        span:hover {
          color: $base-color;
        }

        &.el-dropdown:hover {
          background-color: #fff;
        }
      }
    }
    .top-left{
      float: left;
    }
  }
}

.mobile-qrcode {
  padding: 10px 0;
}

.router-link-active {
  color: $base-color;
}
</style>
